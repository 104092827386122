<template>
    <div>
        <vs-row>
            <vs-col class="text-center">
                <h4>Manage {{ registration.name }}</h4>
                <h6 class="block">{{ registration.description }}</h6>
                <vs-divider />
            </vs-col>
        </vs-row>

        <template v-if="!registration.allow_multiple">
            <SingleRegistrationEntry
                :registration="registration"
                :instance="registration.registrations || null"
                @save="v => save(0, v)"
            />
        </template>
        <template v-else>
            <template v-if="!registration.registrations || registration.registrations.length == 0 || new_registration">
                <SingleRegistrationEntry
                    :registration="registration"
                    :instance="null"
                    @save="v => save(-1, v)"
                />
            </template>
            <template v-else>
                <template v-if="active_registration == null">
                    <template v-for="(reg, index) in registration.registrations">
                        <vx-card :key="index" class="mb-3">
                            <vs-row>
                                <vs-col vs-xs="12" vs-sm="9" vs-lg="9" vs-align="flex-start" vs-justify="center" class="flex" :style="{flexDirection: 'column'}">
                                    <h4>
                                        {{ reg.registration_identifier }}
                                    </h4>
                                    <div class="mt-1">
                                        <vs-chip color="success" v-if="reg.status == 'a'">Approved</vs-chip>
                                        <vs-chip color="warning" v-else-if="reg.status == 'p'">Pending Approval</vs-chip>
                                        <vs-chip color="danger" v-else-if="reg.status == 'r'">Rejected</vs-chip>
                                    </div>
                                </vs-col>
                                <vs-col vs-xs="12" vs-sm="3" vs-lg="3" vs-align="center" class="flex" vs-justify="flex-end">
                                    <vs-button @click="active_registration = index" size="small">
                                        Manage
                                    </vs-button>
                                    <router-link
                                      :to="{name: 'entity-registration-filing-history', params: {registration_type: registration.id, registration_id: reg.id}}"
                                      v-if="registration.integration_code == 'gst' && reg.status == 'a'"
                                    >
                                      <vs-button size="small" class="ml-1">
                                          Filing History
                                      </vs-button>
                                    </router-link>
                                </vs-col>
                            </vs-row>
                        </vx-card>
                    </template>
                    <vs-row class="mt-base">
                        <vs-col class="px-0">
                            <vs-button size="small" @click="new_registration = true">
                                + Add Another
                            </vs-button>
                        </vs-col>
                    </vs-row>
                </template>
                <template v-else>
                    <vs-button size="small" class="mb-3" @click="active_registration = null">
                        Go Back
                    </vs-button>
                    <SingleRegistrationEntry
                        :registration="registration"
                        :instance="registration.registrations[active_registration]"
                        @save="v => save(active_registration, v)"
                    />
                </template>
            </template>
        </template>
    </div>
</template>

<script>
import SingleRegistrationEntry from './SingleRegistrationEntry.vue'

export default {
    data() {
        return {
            registration: null,
            loading: false,

            active_registration: null,
            new_registration: false
        }
    },
    components: {
        SingleRegistrationEntry
    },
    watch: {
        '$route.params.registration_type'() {
            this.load_registration()
        }
    },
    mounted() {
        this.load_registration()
    },
    methods: {
        load_registration() {
            this.loading = true
            this.$vs.loading()

            this.$api(this, 'GET', this.$store.state.apiendpoints.data_entity_registrations, {limit: 50, id: this.$route.params.registration_type, mode: 'full'}).then(
                res => {
                    this.registration = res.results[0]
                    if (!this.registration) {
                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "An unknown error occured while loading data. Please try again.",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                        this.$router.push("/")
                    }
                    this.loading = false
                    this.$vs.loading.close()
                },
                err => {
                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: "An unknown error occurred while loading data. Please try again.",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                    this.loading = false
                    this.$vs.loading.close()
                    this.$router.push("/")
                }
            )
        },
        save(index, value) {

            let registration = {}
            if (this.registration.registrations && index >= 0) {
                if (this.registration.allow_multiple && this.registration.registrations.length > index)
                    registration = this.registration.registrations[index]
                else
                    registration = this.registration.registrations || {}
            }

            if (!registration) return

            let url = this.$store.state.apiendpoints.data_entity_registration
            let request_method = 'POST'

            if (registration.id) {
                url += registration.id + "/"
                request_method = 'PATCH'
            }

            let data = {
                registration_type: this.registration.id,
                registration_identifier: value.registration_identifier
            }

            if (this.last_save)
                return this.save_data(this.last_save, value.data)

            this.$api(this, request_method, url, null, data).then(
                res => {
                    this.last_save = res
                    this.save_data(this.last_save, value.data)
                }
            )

        },
        save_data(res, data) {
            let payload = new FormData()
            Object.keys(data).forEach(key => {
                payload.append(key, data[key])
            })

            let url = this.sprintf(
                this.$store.state.apiendpoints.data_entity_registrations_data,
                res.id
            )

            this.$api(this, 'POST', url, null, payload).then(
                res => {
                    this.$vs.notify({
                        time: 2500,
                        title: 'Success',
                        text: "Saved Successfully!",
                        iconPack: 'feather',
                        icon: 'icon-check-circle',
                        color: 'success'
                    })
                    this.$router.push({name: 'entity-registrations'})
                },
                err => {
                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: "An unknown error occurred while loading data. Please try again.",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        }
    }
}
</script>
