<template>
    <div>
        <vs-table :data="results" class="compliance-table">
            <template slot="thead">
                <th>Compliance</th>
                <th>Active</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th></th>
            </template>

            <template v-for="(item, index) in results">
                <tr :key="index">
                    <td>{{ item.name }}</td>
                    <template v-if="item.entity_compliance">
                        <td>
                            <vs-checkbox v-model="item.entity_compliance.enabled" />
                            <small class="block text-danger" v-if="item.api_errors && item.api_errors.enabled">
                                {{  item.api_errors.enabled.join(', ') }}
                            </small>
                        </td>
                        <td>
                            <SelectDate :selectedDate.sync="item.entity_compliance.start_date" />
                            <small class="block text-danger" v-if="item.api_errors && item.api_errors.start_date">
                                {{  item.api_errors.start_date.join(', ') }}
                            </small>
                        </td>
                        <td>
                            <SelectDate :selectedDate.sync="item.entity_compliance.emd_date" />
                            <small class="block text-danger" v-if="item.api_errors && item.api_errors.end_date">
                                {{  item.api_errors.end_date.join(', ') }}
                            </small>
                        </td>
                    </template>
                    <template v-else>
                        <td colspan="3" class="text-center">
                            Not Enabled
                        </td>
                    </template>
                    <td class="text-right">
                        <vs-button
                            v-if="!item.entity_compliance"
                            type="border"
                            color="primary"
                            size="small"
                            @click="enable(index)"
                        >
                            Enable
                        </vs-button>
                        <template v-else>
                            <vs-button
                                type="border"
                                color="primary"
                                size="small"
                                @click="save(index)"
                            >
                                Update
                            </vs-button>
                        </template>
                    </td>
                </tr>
            </template>
        </vs-table>
    </div>
</template>

<script>
export default {
    props: {
        registration: {
            type: Object,
            required: true
        },
        instance: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            results: []
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.$vs.loading();

            let url = this.$store.state.apiendpoints.data_entity_compliances
            let payload = {
                registration_type: this.registration.id,
                registration: this.instance.id,
                limit: 100
            }

            this.$api(this, 'GET', url, payload).then(
                (res) => {
                    this.results = res.results
                    this.$vs.loading.close();
                },
                (err) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: "An error occurred. Please try again later.",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        },
        save(index) {
            let item = this.results[index]
            if (!item || !item.entity_compliance) return

            this.$vs.loading();

            let url = this.sprintf(
                this.$store.state.apiendpoints.data_entity_compliance,
                item.entity_compliance.id
            )

            let payload = item.entity_compliance

            this.$api(this, 'PATCH', url, null, payload).then(
                (res) => {
                    this.$vs.notify({
                        time: 2500,
                        title: 'Success',
                        text: "Action Completed Successfully!",
                        iconPack: 'feather',
                        icon: 'icon-check-circle',
                        color: 'success'
                    })
                    this.$vs.loading.close()
                    this.init()
                },
                (err) => {
                    this.$vs.loading.close();
                    let msg = "An error occurred, please try again later."

                    if (err.error) {
                        this.$set(this.results[index], 'api_errors', err.error)
                        msg = "Please fix the errors and try again."
                    }

                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: msg,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        },
        enable(index) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: `Are you sure?`,
                text: 'Enable the selected compliance, this will start tracking the compliance filing history?',
                acceptText: 'Yes',
                cancelText: 'Go Back',
                accept: () => this._do_action(index, 'activate'),
                cancel: () => null
            })
        },
        _do_action(index, action) {

            let item = this.results[index]

            let url = this.sprintf(
                this.$store.state.apiendpoints.data_entity_compliance_registration_action,
                item.id,
                this.instance.id
            )

            let payload = { action }

            this.$vs.loading()

            this.$api(this, 'POST', url, null, payload).then(
                res => {
                    this.$vs.notify({
                        time: 2500,
                        title: 'Success',
                        text: "Action Completed Successfully!",
                        iconPack: 'feather',
                        icon: 'icon-check-circle',
                        color: 'success'
                    })
                    this.$vs.loading.close()
                    this.init()
                },
                err => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: "An error occurred. Please try again later.",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        }
    }
}
</script>

<style lang="scss">
  .compliance-table {
    .vs-con-tbody {
        padding-bottom: 350px;
    }
  }
</style>
