<template>
  <div>
      <vx-card>
          <vs-row>
              <!-- Default Field -->
              <vs-col
                  class="mb-5"
                  vs-xs="12"
                  vs-sm="4"
                  vs-lg="4"
              >
                  <label class="vs-input--label font-bold block mb-1 px-0">
                      {{ registration.identifier_label_singular }}<sup class="text-danger">*</sup>
                  </label>

                  <vs-input
                      v-model="registration_number"
                      class="w-full"
                      :disabled="required_fields_disabled || disabled"
                  />
                  <small class="block mt-2 text-muted">
                      <template v-if="instance && instance.status == 'p'">
                          Pending Verification
                      </template>
                      <template v-else-if="instance && instance.status == 'a'">
                          Verified
                      </template>
                  </small>
                  <small class="block mt-2 text-danger">
                      {{ (errors['registration_number'] || []).join(', ') }}
                  </small>
              </vs-col>
              <vs-col
                  v-for="(field, index) in registration.registration_fields"
                  :key="index"
                  class="mb-5"
                  vs-xs="12"
                  :vs-sm="['address'].includes(field.field_type) ? 12 : 4"
                  :vs-lg="['address'].includes(field.field_type) ? 12 : 4"
              >
                  <label class="vs-input--label font-bold block mb-1 px-0">
                      {{ field.name }}<sup v-if="field.required" class="text-danger">*</sup>
                  </label>
                  <template v-if="field.properties && (field.properties.options || []).length > 0">
                      <InputSelect
                          :options="field.properties.options"
                          :model.sync="form[field.id]"
                          placeholder="Select from List"
                          :disabled="(field.required && required_fields_disabled) || disabled"
                      />
                  </template>
                  <template v-else-if="['string', 'string_alpha', 'pan', 'aadhar', 'address'].includes(field.field_type)">
                      <vs-input
                          v-model="form[field.id]"
                          class="w-full"
                          :disabled="(field.required && required_fields_disabled) || disabled"
                      />
                  </template>
                  <template v-else-if="['email'].includes(field.field_type)">
                      <vs-input
                          type="email"
                          v-model="form[field.id]"
                          class="w-full"
                          :disabled="(field.required && required_fields_disabled) || disabled"
                      />
                  </template>
                  <template v-else-if="['float', 'integer', 'phone_number'].includes(field.field_type)">
                      <vs-input
                          type="number"
                          :step="field.field_type == 'float' ? '0.01': '0'"
                          v-model="form[field.id]"
                          class="w-full"
                          :disabled="(field.required && required_fields_disabled) || disabled"
                      />
                  </template>
                  <template v-else-if="['date'].includes(field.field_type)">
                      <SelectDate
                          :selectedDate.sync="form[field.id]"
                          :allow_all_dates="true"
                          :disabled="(field.required && required_fields_disabled) || disabled"
                      />
                  </template>
                  <template v-else-if="field.field_type == 'file'">
                      <FileSelection
                          :value.sync="form[field.id]"
                          :extensions="['jpg', 'pdf', 'png', 'jpeg']"
                          :readonly="(field.required && required_fields_disabled) || disabled"
                      />
                  </template>
                  <template v-else>
                      Unsupported Field {{ field.field_type }}
                  </template>

                  <small class="block mt-2 text-muted">
                      {{ field.description || "" }}
                  </small>
                  <small class="block mt-2 text-danger">
                      {{ (errors[field.id] || []).join(', ') }}
                  </small>
              </vs-col>
          </vs-row>

          <vs-row class="mt-base">
              <vs-col class="text-danger">
                  * Required Fields
              </vs-col>
          </vs-row>
      </vx-card>

      <vs-row class="mt-base">
          <vs-col class="text-center">
              <vs-button color="primary" type="filled" @click="save" :disabled="disabled">Save</vs-button>
          </vs-col>
      </vs-row>
  </div>
</template>

<script>
export default {
  props: {
      registration: {
          type: Object,
          required: true
      },
      instance: {
          type: Object,
          required: false
      },
      api_errors: {
          type: Object,
          required: false,
          default: () => { return {} }
      },
      disabled: {
          type: Boolean,
          required: false,
          default: false
      }
  },
  data() {
      return {
          registration_number: null,
          form: {},
          field_errors: {},
          errors: {}
      }
  },
  computed: {
      required_fields_disabled() {
          console.log('checking', this.instance)
          if (!this.instance) return false
          if (this.instance.status == 'r') return false

          return true
      }
  },
  watch: {
      api_errors: {
          deep: true,
          handler: function () {
              this.errors = this.calculate_errors()
          }
      },
      field_errors: {
          deep: true,
          handler: function () {
              this.errors = this.calculate_errors()
          }
      },
      instance() {
          this.fill_data_from_instance()
      }
  },
  mounted() {
      this.fill_data_from_instance()
  },
  methods: {
      save() {
          this.field_errors = {}
          if (!this.registration_number || this.registration_number.toString().trim().length == 0) {
              this.$set(this.field_errors, 'registration_number', ["This field is required", ])
          } else {
              if (this.registration.identifier_pattern_match) {
                  let r = new RegExp(this.registration.identifier_pattern_match)
                  if (!r.test(this.registration_number)) {
                      this.$set(this.field_errors, 'registration_number', ["Enter a valid value", ])
                  }
              }
          }
          for(let field_index in this.registration.registration_fields) {
              let field = this.registration.registration_fields[field_index]
              let err = this.validate_field(field)
              if (err !== true) {
                  this.$set(this.field_errors, field.id, [err, ])
              }
          }

          if (Object.keys(this.field_errors).length == 0) {
              this.$emit('save', this.finalise_data())
          }
      },
      finalise_data() {
          console.log(this.form)
          return {
              registration_identifier: this.registration_number,
              data: this.form
          }
      },
      validate_field(field) {
          let value = this.form[field.id]

          let is_empty = value == null || value == undefined || value.toString().trim().length == 0

          if (field.required) {
              if (is_empty) {
                  return "This field is required"
              }
          }

          if (is_empty) return true

          let regex_test = {
              "string": /^[a-zA-Z\s]+$/g,
              "string_alpha": /^[a-zA-Z0-9\s]+$/g,
              "integer": /^[0-9]+$/g,
              "float": /^[0-9]+\.[0-9]+$/g,
              "pan": /^([A-Za-z]{5})([0-9]{4})([A-Za-z]{1})$/,
              "email": /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
          }

          if (regex_test[field.field_type]) {
              if (!regex_test[field.field_type].test(value)) {
                  return "Please enter a valid value"
              }
          }

          return true
      },
      calculate_errors() {
          let err = this.deepclone(this.api_errors)
          if (!err) {
              err = this.field_errors || {}

              return err
          }

          err = err || {}

          Object.keys(this.field_errors).forEach(e => {
              err[e] = [
                  ...(err[e] || []),
                  ...this.field_errors[e]
              ]
          })

          return err
      },
      fill_data_from_instance() {
          console.log("Filling data", this.instance)
          if (!this.instance) return

          this.registration_number = this.instance.registration_identifier

          let fields = this.instance.registration_fields || []
          fields.forEach(item => {
              this.form[item.field] = item.value || item.file
          })
      }
  }
}
</script>

<style scoped lang="scss">
  .text-muted {
      color: rgba(0,0,0,0.4);
  }
</style>
