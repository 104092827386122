<template>
    <vs-tabs v-if="instance && instance.status == 'a'">
        <vs-tab label="Data">
            <Data
                :registration="registration"
                :instance="instance"
                :api_errors="api_errors"
                :disabled="disabled"
                @save="v => $emit('save', v)"
            />
        </vs-tab>
        <vs-tab label="Compliances">
            <Compliances
                :registration="registration"
                :instance="instance"
            />
        </vs-tab>
    </vs-tabs>
    <Data
        v-else
        :registration="registration"
        :instance="instance"
        :api_errors="api_errors"
        :disabled="disabled"
        @save="v => $emit('save', v)"
    />
</template>
<script>

import Data from "./components/Data.vue"
import Compliances from "./components/Compliances.vue"

export default {
  props: {
        registration: {
            type: Object,
            required: true
        },
        instance: {
            type: Object,
            required: false
        },
        api_errors: {
            type: Object,
            required: false,
            default: () => { return {} }
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Data,
        Compliances
    }
}
</script>
